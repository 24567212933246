import React from 'react'
import style from './Worklayout.module.css';
import img1 from '../../images/kbtynreal.jpg';
import img2 from '../../images/p2.jpg'
import img3 from '../../images/tictac.jpg'





const Worklayout = ({tech,title,desc,img,github,demo}) => {

  return (
    <div className={style.container}>
      <div className={style.pictitle}>
        <img className={style.pic}  src={img==="1"? img1: img==="2"? img2:img3} alt=""/>
        <div className={style.title}>

        <p className={style.tt}>{title}</p>

        <div className={style.links}>
          
          <a target="_blank" href={github} className={style.link}>
           
            Project Github 
          </a>
          <a target="_blank" href={demo} className={style.link}>
            Live demo
          </a>

        </div>



        </div>

      </div>

      <div className={style.paragcont}>
        <p className={style.paratitle}>Description</p>
        <p className={style.para}>{desc}</p>

      </div>

     

      <div className={style.tech}>
        <p className={style.techtitle}>Technologies used</p>
        <div className={style.techtypes}>

          {tech.map((t)=>
            <p className={style.techicon} >{t} </p>
          )}

        

        </div>
      

      </div>





      
    </div>
  )
}

export default Worklayout

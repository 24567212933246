import React from 'react'
import Logo from '../../component/logo/Logo'
import style from './About.module.css';

const About = () => {




  return (
    <>
    <div className={style.container}>

    <div className={style.text}>


    <div className={style.title}>

       About me

    </div>

    <div className={style.paragraph}>
      I am a KFUPM student currently placed in Dharhan, Very passionate  learner and great team player. I was part of several projects and learned many technologies that are not  tought to us by the university
    </div>

    <div className={style.paragraph}>
      At my own time   I practice both Music and Archery. I play the famous  Arabic instrenument  Oud. I am very passionate about music as much I am with Learning. I also do some Target shooting with Bow and arrow and look forward to be good  at it.
    </div>

    <div className={style.paragraph}>
      I love meeting and interacting with new people from different cultures and countries
      
    </div>
    


      
       

   

    



    </div>


    <div className={style.figure}>
      <Logo/>
    </div>


  </div>
    
  </>
  )
}

export default About

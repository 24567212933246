import React from 'react'
import style from './Logo.module.css';
import { useSpring, animated,config } from 'react-spring';


//function that returns an array of the x and y we need when we have the animation
const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1]

//function to add  the css and using it for interpolar function
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const Logo = () => {

     
  
  
  const [animate, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }))


  return (
    <>

    <animated.div className={style.logo}
    
    onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: animate.xys.interpolate(trans) }}
    
    
    
    
    
    
    
    
    >
      B
    </animated.div>
      
    </>
  )
}

export default Logo

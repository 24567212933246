import React from 'react'
import Worklayout from '../../component/worklayout/Worklayout'
import style from './Work.module.css';
import {workData} from './data'


const Work = () => {


  return (
   
    <div className={style.container}>
      {workData.map((proj)=>
      <Worklayout className={style.layout} tech={proj.tech} img={proj.img} title={proj.title} desc={proj.desc} demo={proj.demo} github={proj.github}/>
      
      )}
    </div>
  )
}

export default Work

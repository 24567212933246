import React,{useState} from 'react';
import style from './Home.module.css';
import { useSpring, animated,config } from 'react-spring';
import Letter from '../../component/Sidemenu/letter/Letter';
import Logo from '../../component/logo/Logo';



const Home = () => {
       
  //text
  const first= ['H','e','l','l','o',","];
  const second= ['M',"y\xa0",'n','a','m','e'," \xa0",'i','s'," \xa0",'B','a','n','d','a','r',","];
  const third =['C',"o ",'m','p',' u','t',' e','r','\xa0','S','c','i',"e","n",'c','e','\xa0','S','t','u'," d"," e"," n"," t",","];
  


 // const {x} = useSpring({ from:{x:0},x:isHovered ? 1:0,config:{duration:1000}, onRest:()=>{setHovered(false)}})
       //             color:isHovered ?"#BB595F": "#ffffff",


      //  style={{
      //   color:isHovered ?"#BB595F": "#ffffff",            transform: x
      //     .interpolate({
      //       range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
      //       output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1]
      //     })
      //     .interpolate(x => `scaley(${x})`)
      // }}


  return (
    <>
    <div className={style.container}>

      <div className={style.text}>
         <div className={style.firstrow}>
         

            {first.map((letter,key)=><Letter letter={letter}/>)}



         </div>


         <div className={style.secondrow}>
  
         {second.map((letter,key)=><Letter letter={letter}/>)}

         </div>


         
      <div className={style.thirdrow}>

{third.map((letter,key)=><Letter letter={letter}/>)}

</div>


     

      



      </div>


      <div className={style.figure}>
        <Logo/>
      </div>


    </div>
      
    </>
  )
}

export default Home

import React,{useState} from 'react'
import { useSpring, animated,config } from 'react-spring';
import style from './Letter.module.css';


const Letter = ({letter}) => {
  const [isHovered,setHovered]=useState(false);

  const animate= useSpring({transform:isHovered ? "scaleY(1)":"scaleY(1.2)",config:config.stiff,onRest:()=>setHovered(false)});

  
  
  return (
    
    <>
       <animated.p className={style.letter} style={animate}   onMouseEnter={()=>{setHovered(true);}}>{letter}</animated.p>
    </>
  )
}

export default Letter

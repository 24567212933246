import React,{useState} from 'react'
import style from './update.module.css';
import { useSpring, animated,config } from 'react-spring';


const Update = ({isActive,text}) => {
    

    const animate =useSpring({
      loop:false,
      to:{
        opacity: isActive? 1:0,

      }


    })
   

  return (
    <animated.div
    
    style={animate}
    
    className={style.container}>
      <p className={style.text}>
         {text} 
      </p>
      
    </animated.div>
  )
}

export default Update

import React, { useState } from 'react'
import Logo from '../../component/logo/Logo'
import style from './Contact.module.css';
import emailjs from 'emailjs-com';
import update from '../../component/update/Update';
import Update from '../../component/update/Update';


const Contact = () => {
   

  const [isActive,setIsActive]= useState(false);
  const [isComplete,setComplete]=useState(false);


  const handleEmail = (e)=>{

    if(e.target!=="")
    setComplete(true)

  }


  
const submit =(e)=>{
    
  if(isComplete){

  e.preventDefault();
    
    emailjs.sendForm('service_pw8mzj1', 'template_lkmpffj', e.target, 'user_dKBU1UHvyxf4owMP69jZK')
      .then((result) => {
          setIsActive(true)
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset();
    }

      


}






  return (
    <>
    <div className={style.container}>
    <Update isActive={isActive} text="Your message was sent succesfully"/>


    <div className={style.text}>


    <div className={style.title}>

    Contact me

    </div>

    <form onSubmit={submit}>

    <div className={style.formgroup}>
            <input type="text" name="name" placeholder="Name" id="name"/>
    </div>
      <div className={style.sec}>

    <div className={style.formgroup}>
            <input onChange={handleEmail}  type="text" name="email" placeholder="Email" id="email"/>
    </div>


    

    </div>


    <textarea placeholder="Message"  name="message" id="message" >



    </textarea>



    <div className={style.formgroup}>


    <button type="submit"  className={style.btn}>

      Send

    </button>
    </div>
    </form>



       





    </div>


    <div className={style.figure}>
      <Logo/>
    </div>


  </div>
    
  </>
  )
}

export default Contact

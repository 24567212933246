


export const workData=[
      {
        title:'Kbtyn App',
        github:'https://github.com/Bandar-f/SWE363_Project',
        demo:"https://amazing-edison-55902e.netlify.app/",
        desc:'This application memics the needs of a delivery or carpool application where the user can choose the place he or she wants to go and fill up the application, than the system will receive the request and move the applicatoin until finally it is approved.',
        tech:["Mongo db","React js","Express js"],
        img:"1",


      
      },
      {
        title:'Clubs System',
        github:'https://github.com/Bandar-f/ICS324proj',
        demo:"https://frosty-nobel-baceee.netlify.app",
        desc:'the main purpose of this application is to create a good sql design where the tables actually make it easier to build and understand the requirements. The frondend and backend are very symbol application which were made in two days just to test the sql.',
        tech:["mySql","React js","Express js"],
        img:"2",


      
      },{
      title:'Tic Tac Toe',
      github:'https://github.com/Bandar-f/tic-tac-toe',
      demo:"https://suspicious-lamport-d4810d.netlify.app/",
      desc:'This is an application of the game theory and AI, more specificaly on the minimax algorithm. I have used react to display the board in which the human can interact and  play the game against the computer. and Since the game is solved, there is no way for the user to win, same with the AI if the human played perfectly',
      tech:["minimax AI","React js","Engine Design"],
      img:"3",

    }
      
     
   
      
      ]
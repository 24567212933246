import React from 'react'
import Logo from '../../component/logo/Logo'
import style from './Skills.module.css';
import Letter from '../../component/Sidemenu/letter/Letter';


const Skills = () => {

  const icon=["React js","CSS","Responsive Design"];

  const string="My main field is web development, I have been working with react for a year and had some basic and intermediate projects.Beside that I love playing with algorithms in general  and I look forward to get invovled with AI real prjects.";

  

 



  return (
    <>
    <div className={style.container}>

    <div className={style.text}>

      <div className={style.title}>

        My Skills

      </div>

      <div className={style.paragraph}>
        {string}
      </div>

      <div className={style.skillicon}>
      {icon.map((t)=>
            <p className={style.icon} >{t} </p>
          )}

      </div>
     



    </div>


    <div className={style.figure}>
      <Logo/>
    </div>


  </div>
    
  </>
  )
}

export default Skills

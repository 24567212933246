import React,{useState} from 'react'
import style from './Sidemenu.module.css';
import {RiHome2Line} from 'react-icons/ri';
import {BsPerson} from 'react-icons/bs';
import {TiLightbulb} from 'react-icons/ti';
import {GrWorkshop} from 'react-icons/gr';
import {BiMessage} from 'react-icons/bi';
import {FaTwitter,FaLinkedinIn,FaYoutube} from 'react-icons/fa';
import {AiOutlineEye} from 'react-icons/ai';
import { useSpring, animated,config } from 'react-spring';
import { NavLink,useLocation } from 'react-router-dom';




const Sidemenu = () => {


   const myLinked="https://www.linkedin.com/in/bandar-albalawi/"

  const [onHoverEffect,setOnHover]= useState(0);

  const [showText,setText]=useState("");

  const animateText=useSpring({opacity:showText!=="" ? 1:0,config:{friction:40},});
  let currentRoute = useLocation().pathname;



  return (
    <>


    <div className={style.sidemenu}>


      <div className={style.logo}>
         B
      </div>


      <div className={style.menu}>

           <NavLink className={currentRoute!=="/"? style.link:style.selected} to="/">
              {showText==="Home"? 
              <animated.p onMouseOut={()=>{setText("")}} className={style.detail} style={animateText} >Home</animated.p>:       
              <animated.span onMouseOver={()=>{setText("Home")}}>
                     <RiHome2Line  
                        />          
        
                </animated.span>}

                </NavLink>

                <NavLink className={currentRoute!=="/about"? style.link:style.selected} to="/about" >

               {showText==="About"?  <animated.p onMouseOut={()=>{setText("")}} className={style.detail} style={animateText} >About</animated.p>:
                <animated.span onMouseOver={()=>{setText("About")}}>
             <BsPerson />
             </animated.span>}
             </NavLink>



             <NavLink className={currentRoute!=="/skills"? style.link:style.selected} to="/skills" >

             {showText==="Skills"?  <animated.p onMouseOut={()=>{setText("")}} className={style.detail} style={animateText} >Skills</animated.p>:
                <animated.span onMouseOver={()=>{setText("Skills")}}>
             <TiLightbulb />
             </animated.span>}

             </NavLink>


             <NavLink className={currentRoute!=="/work"? style.link:style.selected} to="/work" >

             {showText==="Work"?  <animated.p onMouseOut={()=>{setText("")}} className={style.detail} style={animateText} >Work</animated.p>:
                <animated.span onMouseOver={()=>{setText("Work")}}>
             <AiOutlineEye  />
             </animated.span>}

             </NavLink>


             <NavLink  className={currentRoute!=="/contact"? style.link:style.selected} to="/contact" >


             {showText==="Contact"?  <animated.p onMouseOut={()=>{setText("")}} className={style.detail} style={animateText} >Contact</animated.p>:
                <animated.span onMouseOver={()=>{setText("Contact")}}>
             <BiMessage />
             </animated.span>}

             </NavLink>





      </div>



      <div className={style.contact}>
      <a target="_blank" href={myLinked}>
      <FaLinkedinIn className={style.icons}/>
      </a>
      <FaYoutube className={style.icons}/>
      <FaTwitter className={style.icons}/>



      </div>





    </div>
      
    </>
  )
}

export default Sidemenu

import './App.css';
import React, {useContext} from 'react'
import Sidemenu from './component/Sidemenu/Sidemenu';
import Home from './pages/home/Home';
import {Switch,Route} from 'react-router-dom';
import {useTransition, animated} from 'react-spring';
import About from './pages/about/About';
import Skills from './pages/skills/Skills';
import Work from './pages/work/Work';
import Contact from './pages/contact/Contact';

function App() {

  

  return (
    <>

    <div className="page">
        <Sidemenu/>  

        <Switch>
             
            <Route exact path="/">
                 <Home/>
            </Route>

            <Route exact path="/about">
                 <About/>
            </Route>
            <Route exact path="/skills">
                 <Skills/>
            </Route>
            <Route exact path="/work">
                 <Work/>
            </Route>
            <Route exact path="/contact">
                 <Contact/>
            </Route>


      </Switch>
    </div>
    
    </>
  );
}

export default App;
